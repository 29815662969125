/* eslint-disable max-len */
/* eslint no-unsafe-optional-chaining: "error" */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint no-unsafe-optional-chaining: "error" */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  faChevronLeft,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import Modal from 'react-modal';
// Components
import Layout from '../../components/template/Layout';
import useAppContext from '../../store/useAppContext';
import cn from '../../utils/cn';
import styles from './EditDairy.module.css';
import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Select from '../../components/atoms/Select/Select';
import Textarea from '../../components/atoms/Textarea/Textarea';
import CanvasSignature from '../../components/atoms/Canvas/Canvas';
import Permissions from '../../components/molecules/Permissions/Permissions';
// Services
import { updateDairy, getDairyDetail } from '../../services/dairy';
import { getEquipmentsList } from '../../services/equipments';
import { getBuildingsList } from '../../services/structures';
import { getInterventionsList } from '../../services/interventions';

function EditDairies() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signature, setSignature] = React.useState('');
  const [context] = useAppContext();
  const urlParams = useParams();
  const [valider, setValider] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [batiment, setBatiment] = React.useState([]);
  const [submit, setSubmit] = React.useState(false);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const validationModalSchema = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const getInterventionsListQuery = useQuery(['getInterventionsList'], () => getInterventionsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }));

  const getEquipmentsListQuery = useQuery(['getEquipmentsList'], () => getEquipmentsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }));

  const addDairyMutation = useMutation(updateDairy, {
    onSuccess: () => {
      setSubmit(true);
      navigate(-1);
      toast.success(t('dairy.succes'));
    },
    onError: () => {
      toast.error(t('dairy.error'));
    },
  });
  const validationSchema = Yup.object({
    data: Yup.string().default(new Date().toLocaleDateString()),
    regie: Yup.boolean().default(false),
    stakeholder: Yup.string(),
    company: Yup.string()
      .when('regie', {
        is: true,
        then: Yup.string(),
      }),
    buildingId: Yup.string(),
    equipmentId: Yup.string(),
    interventionType: Yup.string(),
    signature: Yup.mixed(),
    action: Yup.string(),
  });
  const initialValues = {
    signature: null,
    date: new Date().toLocaleDateString(),
    regie: false,
    stakeholder: '',
    company: '',
    buildingId: batiment[0]?.id,
    equipmentId: '',
    interventionType: '',
    action: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (values.regie === true) {
        const regieoff = {
          company: '',
          date: values?.date,
          stakeholder: values?.stakeholder,
          bookletId: context?.choiceBooklet,
          buildingId: values?.buildingId,
          equipmentId: values?.equipmentId,
          interventionType: values?.interventionType,
          action: values?.action,
          id: urlParams?.id,
        };
        addDairyMutation.mutate(regieoff);
      } else {
        const regieon = {
          company: values?.company,
          date: values?.date,
          stakeholder: values?.stakeholder,
          bookletId: context?.choiceBooklet,
          buildingId: values?.buildingId,
          equipmentId: values?.equipmentId,
          interventionType: values?.interventionType,
          action: values?.action,
          id: urlParams?.id,
        };
        addDairyMutation.mutate(regieon);
      }
    },
  });
  const getBuildingsListQuery = useQuery(['getBuildingsList'], () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }), {
    onSuccess: (data) => {
      setBatiment(data?.data?.buildings);
      formik.setFieldValue('buildingId', data?.data?.buildings[0]?.id);
    },
  });
  const handleValider = () => {
    setValider(false);
  };
  const handletocancel = () => {
    navigate(-1);
    toast.success(t('dairy.cancel'));
  };
  const sigCanvas = React.useRef({});
  const trim = () => {
    setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    formik.setFieldValue('signature', sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    setOpenModal(false);
  };
  const clear = () => {
    sigCanvas.current.clear();
    setSignature('');
  };

  const getDairyDetailQuery = useQuery(['getDairyDetail'], () => getDairyDetail({
    id: urlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    enabled: getBuildingsListQuery.isSuccess,
    onSuccess: (data) => {
      formik.setFieldValue('date', format(new Date(data?.data?.date), 'yyyy-MM-dd'));
      formik.setFieldValue('regie', data?.data?.company === '');
      formik.setFieldValue('stakeholder', data?.data?.stakeholder);
      formik.setFieldValue('company', data?.data?.company);
      formik.setFieldValue('equipmentId', data?.data?.equipmentId);
      formik.setFieldValue('buildingId', getBuildingsListQuery.data?.data?.buildings?.find((option) => option?.name === data?.data?.buildingConcerned)?.id);
      formik.setFieldValue('action', data?.data?.action);
      formik.setFieldValue('interventionType', data?.data?.interventionType);
    },
  });
  const Intervention = [
    {
      label: 'Commission de sécurité',
      value: 'safety_committee',
    },
    {
      label: 'Contrôle organisme agréé',
      value: 'approved_body_control',
    },
    {
      label: 'Contrôle de maintenance',
      value: 'maintenance_control',
    },
    {
      label: 'Contrôle d\'un laboratoire',
      value: 'laboratory_control',
    },
    {
      label: 'Formation',
      value: 'training',
    },
    {
      label: 'Dépannage/ Travaux',
      value: 'troubleshooting_or_worksite',
    },
    {
      label: 'Autre',
      value: 'other',
    },
  ];

  const mapForSelectBuilding = (arrayToMap) => (
    arrayToMap?.length
      ? sortBy(arrayToMap, ['name']).map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      : []
  );
  return (
    <>
      <Layout
        title="journal"
        layout="table"
        queryError={
          getInterventionsListQuery?.error
          || getEquipmentsListQuery?.error
          || getBuildingsListQuery?.error
          || getDairyDetailQuery?.error
          || addDairyMutation?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={submit === false ? () => setValider(true) : () => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('dairy.addreturn')}</span>
            </button>
          </div>
          <div className={cn(['row', 'row'])}>
            <h1 className="title">{t('dairy.edit')}</h1>
          </div>
        </header>
        <div>
          <form onSubmit={formik.handleSubmit} className="form shadow-sm">
            <div className="form_group">
              <Input
                type="date"
                name="date"
                label={t('dairy.date')}
                min={new Date()?.toLocaleDateString()}
                placeholder={new Date()?.toLocaleDateString()}
                value={formik?.values?.date}
                onChange={formik?.handleChange}
              />
              {formik?.errors?.date && formik?.touched?.date ? (
                <div className="error">
                  {t(formik?.errors?.date)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {batiment?.length > 1 ? (
                <Select
                  name="buildingId"
                  label={t('dairy.batiment')}
                  options={
                    mapForSelectBuilding(batiment)
                  }
                  value={mapForSelectBuilding(batiment)?.find((options) => options?.value === formik?.values?.buildingId)}
                  onChange={(option) => formik?.setFieldValue('buildingId', option?.value)}
                  loading={getBuildingsListQuery.isLoading}
                />
              ) : (
                <Input
                  type="text"
                  name="buildingId"
                  label={t('dairy.batiment')}
                  placeholder={batiment[0]?.name}
                  value={batiment[0]?.name}
                  disabled
                />
              )}
              {formik?.errors?.buildingId && formik?.touched?.buildingId ? (
                <div className="error">
                  {t(formik?.errors?.buildingId)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="company"
                label={t('dairy.society')}
                onChange={formik?.handleChange}
                value={formik?.values?.company}
                required={formik?.values?.regie === false}
                disabled={formik?.values?.regie === true}
              />
              {formik?.errors?.company && formik?.touched?.company ? (
                <div className="error">
                  {t(formik?.errors?.company)}
                </div>
              ) : null }
            </div>
            <div className={cn(['form_group', styles?.regie])}>
              <Checkbox
                id="regie"
                name="regie"
                label={t('dairy.regie')}
                value={formik?.values?.regie}
                onChange={formik?.handleChange}
                checked={formik?.values?.regie}
              />
              {formik?.errors?.regie && formik?.touched?.regie ? (
                <div className="error">
                  {t(formik?.errors?.regie)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="stakeholder"
                label={t('dairy.intervenant')}
                onChange={formik?.handleChange}
                value={formik?.values?.stakeholder}
              />
              {formik?.errors?.stakeholder && formik?.touched?.stakeholder ? (
                <div className="error">
                  {t(formik?.errors?.stakeholder)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {' '}
            </div>
            <div className="form_group">
              <Select
                name="equipmentId"
                label={t('dairy.equipement')}
                options={mapForSelectBuilding(getEquipmentsListQuery?.data?.data?.equipments)}
                value={mapForSelectBuilding(getEquipmentsListQuery?.data?.data?.equipments)?.find((option) => option?.value === formik?.values?.equipmentId)}
                onChange={(option) => formik?.setFieldValue('equipmentId', option?.value)}
                loading={getEquipmentsListQuery?.isLoading}
              />
              {formik?.errors?.equipmentId && formik?.touched?.equipmentId ? (
                <div className="error">
                  {t(formik?.errors?.equipmentId)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Select
                name="interventionType"
                label={t('dairy.intervention')}
                placeholder={Intervention?.find((option) => option?.value === formik?.values?.interventionType)?.label || t('global.choose')}
                options={Intervention}
                value={Intervention?.find((option) => option?.value === formik?.values?.interventionType)}
                onChange={(option) => formik?.setFieldValue('interventionType', option?.value)}

              />
              {formik?.errors?.interventionType && formik?.touched?.interventionType ? (
                <div className="error">
                  {t(formik?.errors?.interventionType)}
                </div>
              ) : null }
            </div>
            <div className="form_group--fullwidth">
              <Textarea
                name="action"
                label={t('dairy.description')}
                onChange={formik?.handleChange}
                value={formik?.values?.action}
                cols={30}
                rows={5}
              />
              {formik?.errors?.action && formik?.touched?.action ? (
                <div className="error">
                  {t(formik?.errors?.action)}
                </div>
              ) : null }
            </div>
            <div className="form_footer">
              <div className="form_infos">
                <small>{t('add_equipment_family.mandatory_fields')}</small>
              </div>
              {Permissions({ permission: 'DIARY_EDIT' }) !== undefined
                ? (
                  <Button
                    type="submit"
                    className="form_submit"
                    label={t('dairy.submit')}
                  />
                ) : null }
            </div>
          </form>
        </div>
        <footer>
          <div className="row mt-20">
            <button
              type="button"
              className="link"
              onClick={submit === false ? () => setValider(true) : () => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('dairy.addreturn')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className={cn(['modal_actions', styles?.signatureContainer])}>
          <CanvasSignature
            canvasProps={{
              className: styles?.canvas,
            }}
            name="signature"
            trim={() => trim()}
            clear={() => clear()}
            setSignature={setSignature}
            signature={signature}
            sigCanvas={sigCanvas}
          />
        </div>
      </Modal>
      <Modal
        isOpen={valider}
        onRequestClose={() => setValider(false)}
        style={validationModalSchema}
      >
        <p className="modal_paragraph">{t('dairy.valideerror')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('dairy.annuler')}
            onClick={() => handletocancel(false)}
          />
          <Button
            type="submit"
            className="modal_button"
            label={t('dairy.valider')}
            onClick={() => handleValider(true)}
          />
        </div>
      </Modal>
    </>
  );
}

export default EditDairies;
