import React from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import { getremaining, postLift } from '../../services/registre';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './raisedObservation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Textarea from '../../components/atoms/Textarea/Textarea';
import useAppContext from '../../store/useAppContext';
/* eslint-disable arrow-parens */
/* eslint-disable no-lone-blocks */
function finishObservationall() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [info, setInfo] = React.useState([]);
  const location = useLocation();
  const [context] = useAppContext();

  const initialValues = {
    upDate: '',
    prestataireName: '',
    prestataire: false,
    document: null,
    description: '',
    SocietyName: null,
  };
  const getObservationQuery = useQuery(['getObservation'], () => getremaining({
    id: urlParams.id,
    data: {
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    },
  }), {
    onSuccess: (data) => {
      setInfo(data?.data?.observations);
    },
  });
  const postLiftMutation = useMutation(postLift, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const findOption = (value) => {
    const option = info.find((item) => item.id === value);
    return `${option?.title} - ${option?.number}`;
  };

  const validationSchema = Yup.object({
    prestataire: Yup.boolean(),
    upDate: Yup.string().required('global.required_field'),
    prestataireName: Yup.string().required('global.required_field'),
    document: Yup.mixed()
      .nullable()
      .typeError(t('global.wrong_type'))
      .when('prestataire', {
        is: false,
        then: Yup.mixed().nullable()
          .required('global.required_field')
          .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
          .test('type', 'global.accepted_formats', (value) => value && (
            value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg'
          )),
      }),
    description: Yup.string(),
    SocietyName: Yup.string().nullable()
      .when('prestataire', {
        is: false,
        then: Yup.string().required('global.required_field'),
      }),
  });
  const handleSubmit = (values) => {
    const formData = new FormData();
    location.state.observationNumber.forEach((element, index) => {
      formData.append(`observationIds[${index}]`, element);
    });
    formData.append('reportId', urlParams.id);
    formData.append('liftDate', values.upDate);
    formData.append('stakeholder', values.prestataireName);
    if (values.document !== null) {
      formData.append('documentFile', values.document);
    }
    formData.append('actionPerformed', values.description);
    if (values.prestataire !== true) {
      formData.append('society', values.SocietyName);
    }
    postLiftMutation.mutate(formData);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <Layout
      title="finishObservationall"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">
            {t('raisedObs.addall')}
            :
          </h1>
        </div>
        <span className="title-info">
          {getObservationQuery?.data?.data?.interventionName}
        </span>
      </header>
      <div className="title">
        <div>
          {getObservationQuery.isLoading && (
            <div>{t('global.loading')}</div>
          )}
          <form
            onSubmit={formik.handleSubmit}
            className="form shadow-sm"
          >
            <div className={styles.table}>
              Observation - Numéro de rapport
              <br />
              <span style={{ color: 'grey' }}>
                {
                location.state.observationNumber
                  .map((item) => <span className={styles.items}>{findOption(item)}</span>)
                }
              </span>
            </div>
            <div>
              {' '}
            </div>
            <div className="form_group">
              <Input
                type="date"
                name="upDate"
                label={t('raisedObs.upDate')}
                value={formik.values.upDate}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.upDate && formik.touched.upDate ? (
                <div className="error">
                  {t(formik.errors.upDate)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="prestataireName"
                label={t('raisedObs.prestataireName')}
                value={formik.values.prestataireName}
                onChange={(e) => formik.setFieldValue('prestataireName', e.target.value.toUpperCase())}
                required
              />
              {formik.errors.prestataireName && formik.touched.prestataireName ? (
                <div className="error">
                  {t(formik.errors.prestataireName)}
                </div>
              ) : null }
            </div>
            {formik.values.prestataire === false ? (
              <div className="form_group">
                <Input
                  type="text"
                  name="SocietyName"
                  label={t('raisedObs.SocietyName')}
                  value={formik.values.SocietyName}
                  onChange={(e) => formik.setFieldValue('SocietyName', e.target.value.toUpperCase())}
                  required={formik.values.prestataire === false}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
            ) : (
              <div className="form_group">
                <Input
                  type="text"
                  name="SocietyName"
                  label={t('raisedObs.SocietyName')}
                  value={' '}
                  disabled
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
            )}
            <div className={cn(['form_checkbox', styles.check])}>
              <Checkbox
                id="prestataire"
                name="prestataire"
                className={styles.check}
                label={t('raisedObs.byPrestaataire')}
                value={formik.values.prestataire}
                onChange={formik.handleChange}
                checked={formik.values.prestataire}
              />
            </div>
            <div className="form_group--fullwidth">
              <Textarea
                name="description"
                label={t('raisedObs.description')}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.description && formik.touched.description ? (
                <div className="error">
                  {t(formik.errors.description)}
                </div>
              ) : null }
            </div>
            <div className={styles.update}>
              <UploadFile
                id="documentFile"
                name="document"
                label={t('contract.report')}
                fileName={formik.values.document ? formik.values.document.name : formik.values.document}
                onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
                onBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
              />
              {formik.errors.document && formik.touched.document ? (
                <div className="error">
                  {t(formik.errors.document)}
                </div>
              ) : null }
            </div>
            <div className="form_footer">
              <div className="form_infos">
                <small>{t('addInterventions.mandatory_fields')}</small>
              </div>
              <Button
                type="submit"
                className="form_submit"
                label={t('raisedObs.save')}
              />
            </div>
          </form>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default finishObservationall;
