/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-else-return */
import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
// Components
import Layout from '../../components/template/Layout';
import Search from '../../components/molecules/Search/Search';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
// Hooks
import { getIndicator } from '../../services/indicator';
// Styles
import styles from './Indicateur.module.css';
// Utils
import { formatDateToUser } from '../../utils/dates';
import cn from '../../utils/cn';
import { downloadFileCsv } from '../../utils/downloadFile';

function Indicateur() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [bookletChoice, setbookletChoice] = useState(2);
  const [response, setResponse] = useState([]);
  const [dowloadInfo, setDowloadInfo] = useState([]);
  // API Calls
  const handleSearch = debounce((e) => {
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const getIndicatorQuery = useQuery(['indicators', search], () => getIndicator({
    page: 0,
    search,
  }), {
    onSuccess: (data) => {
      setResponse(data?.data?.indicators);
      setDowloadInfo(data?.data?.indicators);
    },
  });
  useEffect(() => {
    // sorry, for this hacky function, but it exist a little defer
    // between the feedback of back after post and the real refresh of list
    // and as back dev say it's not from DB then..
  }, []);

  const isDateBeforeToday = (dateToCheck) => dateToCheck && new Date(dateToCheck) < new Date();

  const handleBuilding = (id) => {
    setbookletChoice(id);
  };

  const hearder = [
    {
      label: `${t('reports.building')}`,
      key: 'buildingName',
      cell: (row) => row?.original?.structure?.name || '-',
    },
    {
      label: `${t('indicateur.division')}`,
      key: 'division',
      cell: (row) => row?.original?.structure?.division?.name || '-',
    },
    {
      label: `${t('indicateur.subsidiary')}`,
      key: 'subsidiary',
      cell: (row) => row?.original?.structure?.subsidiary?.name || '-',
    },
    {
      label: `${t('indicateur.group')}`,
      key: 'group',
      cell: (row) => row?.original?.structure?.group?.name || '-',
    },
    {
      label: `${t('indicateur.recordKeepingRate1')}`,
      key: 'securityRecordKeepingRate1',
      cell: (row) => row?.original?.securityRecordKeepingRate2 || '-',
    },
    {
      label: `${t('indicateur.lateSecurityReportsCount')}`,
      key: 'lateSecurityReportsCount',
      cell: (row) => row?.original?.lateSecurityReportsCount || '-',
    },
    {
      label: `${t('indicateur.totalObservationsToLiftCount')}`,
      key: 'totalObservationsToLiftCount',
      cell: (row) => row?.original?.totalSecurityObservationsToLiftCount || '-',
    },
    {
      label: `${t('indicateur.organizationsSecurityLiftedObservationsPercent')}`,
      key: 'organizationsSecurityLiftedObservationsPercent',
      cell: (row) => row?.original?.organizationsSecurityLiftedObservationsPercent || '-',
    },
    {
      label: `${t('indicateur.organizationsSecurityObservationsToLiftCount')}`,
      key: 'organizationsSecurityObservationsToLiftCount',
      cell: (row) => row?.original?.organizationsSecurityObservationsToLiftCount || '-',
    },
    {
      label: `${t('indicateur.totalObservationsToLiftPercent')}`,
      key: 'totalObservationsToLiftPercent',
      cell: (row) => row?.original?.techniciansSecurityLiftedObservationsPercent || '-',
    },
    {
      label: `${t('indicateur.techniciansSecurityObservationsToLiftCount')}`,
      key: 'techniciansSecurityObservationsToLiftCount',
      cell: (row) => row?.original?.techniciansSecurityObservationsToLiftCount || '-',
    },
    {
      label: `${t('indicateur.totalSecurityLiftedObservationsCount')}`,
      key: 'totalSecurityLiftedObservationsCount',
      cell: (row) => row?.original?.totalSecurityLiftedObservationsCount || '-',
    },
    {
      label: `${t('indicateur.liftedSecurityObservationsPercent')}`,
      key: 'liftedSecurityObservationsPercent',
      cell: (row) => row?.original?.liftedSecurityObservationsPercent || '-',
    },
    {
      label: `${t('indicateur.liftedSecurityPrescriptionsPercent')}`,
      key: 'liftedSecurityPrescriptionsPercent',
      cell: (row) => row?.original?.liftedSecurityPrescriptionsPercent || '-',
    },
    {
      label: `${t('indicateur.securityInputsDonePercent')}`,
      key: 'securityInputsDonePercentLastMonth',
      cell: (row) => row?.original?.securityInputsDonePercentLastMonth || '-',
    },
    {
      label: `${t('indicateur.securityInputsToDoCount')}`,
      key: 'securityInputsToDoCount',
      cell: (row) => row?.original?.securityInputsToDoCount || '-',
    },
    {
      label: `${t('indicateur.lastsecuritycommitteenotice')}`,
      key: 'lastSecurityCommitteeNotice',
      cell: (row) => row?.original?.lastSecurityCommitteeNotice || '-',
    },
    {
      label: `${t('indicateur.lastSecurityCommitteeDate')}`,
      key: 'lastSecurityCommitteeDate',
      cell: (row) => {
        if (row?.original?.lastSecurityCommitteeDate) {
          return formatDateToUser(row?.original?.lastSecurityCommitteeDate?.date);
        }
        return '-';
      },
    },
    {
      label: `${t('indicateur.nextSecurityCommitteeDate')}`,
      key: 'nextSecurityCommitteeDate',
      cell: (row) => {
        if (row?.original?.nextSecurityCommitteeDate) {
          return formatDateToUser(row?.original?.nextSecurityCommitteeDate?.date);
        }
        return '-';
      },
    },
    {
      label: `${t('indicateur.complianceLastSanitaryAnalysisPercent')}`,
      key: 'complianceLastSanitaryAnalysisPercent',
      cell: (row) => row?.original?.complianceLastSanitaryAnalysisPercent || '-',
    },
    {
      label: `${t('indicateur.lastTwoWeekSanitaryInputsDonePercent')}`,
      key: 'lastTwoWeekSanitaryInputsDonePercent',
      cell: (row) => row?.original?.lastTwoWeekSanitaryInputsDonePercent || '-',
    },
    {
      label: `${t('indicateur.lastYearSanitaryInputsDonePercent')}`,
      key: 'lastYearSanitaryInputsDonePercent',
      cell: (row) => row?.original?.lastYearSanitaryInputsDonePercent || '-',
    },
    {
      label: `${t('indicateur.lateSanitaryReportsCount')}`,
      key: 'lateSanitaryReportsCount',
      cell: (row) => row?.original?.lateSanitaryReportsCount || '-',
    },
    {
      label: `${t('indicateur.pneumophilaAnalysisSanitaryPercent')}`,
      key: 'pneumophilaAnalysisSanitaryPercent',
      cell: (row) => row?.original?.pneumophilaAnalysisSanitaryPercent || '-',
    },
    {
      label: `${t('indicateur.prescriptionsSecurityToLiftCount')}`,
      key: 'prescriptionsSecurityToLiftCount',
      cell: (row) => row?.original?.prescriptionsSecurityToLiftCount || '-',
    },
    {
      label: `${t('indicateur.sanitaryInputsDonePercent')}`,
      key: 'sanitaryInputsDonePercent',
      cell: (row) => row?.original?.sanitaryInputsDonePercent || '-',
    },
    {
      label: `${t('indicateur.sanitaryInputsDoneWithAlertPercent')}`,
      key: 'sanitaryInputsDoneWithAlertPercent',
      cell: (row) => row?.original?.sanitaryInputsDoneWithAlertPercent || '-',
    },
    {
      label: `${t('indicateur.sanitaryInputsToDoCount')}`,
      key: 'sanitaryInputsToDoCount',
      cell: (row) => row?.original?.sanitaryInputsToDoCount || '-',
    },
  ];

  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('indicateur.title')}`);
  };
  const columnsSecurite = useMemo(
    () => [
      {
        Header: `${t('indicateur.establishment')}`,
        accessor: 'establishment',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.division')}`,
        accessor: 'division',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.division?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.subsidiary')}`,
        accessor: 'subsidiary',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.subsidiary?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.group')}`,
        accessor: 'group',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.group?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.recordKeepingRate1')}`,
        accessor: 'securityRecordKeepingRate2',
        Cell: ({ row }) => (
          <div>
            {row?.original?.securityRecordKeepingRate2 !== null ? (
              <div>
                {row?.original?.securityRecordKeepingRate2}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.tableLateReportCount')}`,
        accessor: 'lateSecurityReportsCount',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lateSecurityReportsCount !== null ? (
              <div>
                {row?.original?.lateSecurityReportsCount}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.totalObservationsToLiftCount')}`,
        accessor: 'totalSecurityObservationsToLiftCount',
        Cell: ({ row }) => (
          <div>
            {row?.original?.totalSecurityObservationsToLiftCount !== null ? (
              <div>
                {row?.original?.totalSecurityObservationsToLiftCount}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.totalObservationsToLiftPercent')}`,
        accessor: 'liftedSecurityObservationsPercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.liftedSecurityObservationsPercent !== null ? (
              <div>
                {row?.original?.liftedSecurityObservationsPercent}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lastsecuritycommitteenotice')}`,
        accessor: 'lastsecuritycommitteenotice',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastSecurityCommitteeNotice !== null ? (
              <div className={`${row?.original?.lastSecurityCommitteeNotice === 1 ? 'tag--success' : 'tag--error'}`}>
                {row?.original?.lastSecurityCommitteeNotice === 1 ? t('indicateur.favorable') : t('indicateur.unfavorable')}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.nextCommitteeDate')}`,
        accessor: 'nextSecurityCommitteeDate',
        Cell: ({ row: { original: { nextSecurityCommitteeDate } } }) => (
          <div className={`${isDateBeforeToday(nextSecurityCommitteeDate) ? 'tag--error' : ''}`}>
            {formatDateToUser(nextSecurityCommitteeDate?.date)}
          </div>
        ),
      },
    ],
    [],
  );

  const columnsSanitaire = useMemo(
    () => [
      {
        Header: `${t('indicateur.establishment')}`,
        accessor: 'establishment',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.name}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.division')}`,
        accessor: 'division',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.division?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.subsidiary')}`,
        accessor: 'subsidiary',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.subsidiary?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.group')}`,
        accessor: 'group',
        Cell: ({ row }) => (
          <div>
            {row?.original?.structure?.group?.name || '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.sanitaryInputsDonePercent')}`,
        accessor: 'sanitaryInputsDonePercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.sanitaryInputsDonePercent !== null ? (
              <div>
                {row?.original?.sanitaryInputsDonePercent}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lastTwoWeekSanitaryInputsDonePercent')}`,
        accessor: 'lastTwoWeekSanitaryInputsDonePercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastTwoWeekSanitaryInputsDonePercent !== null ? (
              <div>
                {row?.original?.lastTwoWeekSanitaryInputsDonePercent}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.lastYearSanitaryInputsDonePercent')}`,
        accessor: 'lastYearSanitaryInputsDonePercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastYearSanitaryInputsDonePercent !== null ? (
              <div>
                {row?.original?.lastYearSanitaryInputsDonePercent}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.pneumophilaAnalysisPercent')}`,
        accessor: 'pneumophilaAnalysisPercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.pneumophilaAnalysisSanitaryPercent !== null ? (
              <div>
                {row?.original?.pneumophilaAnalysisSanitaryPercent}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.complianceLastSanitaryAnalysisPercent')}`,
        accessor: 'pneumophilaAnalysisSanitaryPercent',
        Cell: ({ row }) => (
          <div>
            {row?.original?.pneumophilaAnalysisSanitaryPercent !== null ? (
              <div>
                {row?.original?.pneumophilaAnalysisSanitaryPercent}
              </div>
            ) : '-'}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.tableLateReportCount')}`,
        accessor: 'lateSanitaryReportsCount',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lateSanitaryReportsCount ? (
              <div>
                {row?.original?.lateSanitaryReportsCount}
              </div>
            ) : '-'}
          </div>
        ),
      },
    ],
    [],
  );

  // exclure toute les lignes qui ont que des - ou 0
  // const filtreEntryData = (data) => data.filter((row) => {
  //   const values = Object.values(row);
  //   values.slice(2).filter((value) => value !== null && value !== 0);
  //   console.log(values.slice(2).filter((value) => value !== null && value !== 0));
  //   return values.slice(2).filter((value) => value !== null && value !== 0);
  // });

  const filtreEntryDate = (responses) => responses?.filter((item) => Object.values(item).some((value) => value !== '-'));
  return (
    <Layout
      title={t('indicateur.title')}
      layout="table"
      queryError={getIndicatorQuery?.error}
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('indicateur.title')}</h1>
        </div>
        <div className="row">
          <button
            className="add"
            type="button"
            onClick={handleDowload}
          >
            <FontAwesomeIcon icon={faFileCsv} />
            {' '}
            {t('reports.download')}
          </button>
          <Search className="mb-20" onChange={handleSearch} />
        </div>
      </header>
      <div className={styles.filters}>
        <div className={styles.filtersToComplete}>
          <Button
            type="button"
            label={t('indicateur.securite')}
            className={cn([styles.filter, bookletChoice === 2
              ? styles.active
              : '', 'shadow-md'])}
            onClick={() => handleBuilding(2)}
          />
          <Button
            type="button"
            label={t('indicateur.sanitaire')}
            className={cn([styles.filter, bookletChoice === 1
              ? styles.active
              : '', 'shadow-md'])}
            onClick={() => handleBuilding(1)}
          />
        </div>
      </div>
      { bookletChoice === 1 && (
      <div className={styles.table}>
        <Table
          columns={columnsSanitaire}
          isLoading={getIndicatorQuery.isLoading}
          data={filtreEntryDate(response) || []}
          hasSort
        />
      </div>
      )}
      { bookletChoice === 2 && (
      <div className={styles.table}>
        <Table
          columns={columnsSecurite}
          isLoading={getIndicatorQuery.isLoading}
          data={filtreEntryDate(response) || []}
          hasSort
        />
      </div>
      )}
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Indicateur;
