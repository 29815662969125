/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faSignOut,
  faUser,
  faUserSecret,
  faBars,
  faXmark,
  faClose,
  faBell,
  faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import useAppContext from '../../store/useAppContext';
// Components
import Navigation from '../molecules/Navigation/Navigation';
import QueryError from '../molecules/QueryError/QueryError';
import Permissions from '../molecules/Permissions/Permissions';
// Utils
import userPicture from '../../assets/img/user.jpg';
import styles from './Layout.module.css';
import cn from '../../utils/cn';
import Button from '../atoms/Button/Button';
import { USERS_ROLES } from '../../utils/constant';
import Notification from '../../views/NotificationDashboard/NotificationDashboard';
import Picto from '../atoms/Picto/Picto';
import { appVersion, getVersionsFront } from '../../services/version';

function Layout({
  title, description, layout, queryError, children, isLoading,
}) {
  // States
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRefresh, setOpenModalRefresh] = useState(false);
  const versionRef = useRef();
  // Hooks
  const { t } = useTranslation();
  const [context, dispatch, currentUser] = useAppContext();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch({ type: 'CLEAR_CONTEXT' });
    navigate('/');
    window.location.reload();
  };

  const handleGoBackAccount = () => {
    dispatch({ type: 'SET_USER', payload: context?.userImpersonated });
    dispatch({ type: 'SET_PERMISSIONS', payload: [] });
    dispatch({ type: 'CLEAR_USER_IMPERSONATED' });
    navigate('/dashboard');
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 60rem)');
    const resize = () => {
      if (mediaQuery.matches) {
        setMenuCollapsed(true);
      } else {
        setMenuCollapsed(false);
      }
    };
    resize();
    mediaQuery.addEventListener('change', resize);
    return () => mediaQuery.removeEventListener('change', resize);
  }, []);

  const isAdminOrSuperAdmin = context?.user?.role === USERS_ROLES.SUPER_ADMIN || context?.user?.role === USERS_ROLES.ADMIN || context?.userImpersonated?.role === USERS_ROLES.SUPER_ADMIN || context?.userImpersonated?.role === USERS_ROLES.ADMIN;
  const nameEstablishment = context?.choiceEstablishment?.name;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      maxWidth: '90%',
      maxHeight: '70%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: '1rem',
      boxShadow: '0.1rem 0.1rem white',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '4rem 2rem 0 2rem',
    },
  };
  const customStylesRefresh = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: '1rem',
      boxShadow: '0.1rem 0.1rem white',
      transform: 'translate(-50%, -50%)',
    },
  };
  const getVersion = useQuery(['front'], () => getVersionsFront(), {
    onSuccess: (data) => {
      if (data?.data?.version !== appVersion) {
        versionRef.current.style.display = 'flex';
      }
    },
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      getVersion.refetch();
    }, 900000); // 900000 millisecondes = 15 minutes
    return () => clearInterval(intervalId);
  }, []);
  const navigateVersion = () => {
    if (context.user.role === USERS_ROLES.SUPER_ADMIN || context.user.role === USERS_ROLES.ADMIN) {
      navigate('/version');
    }
  };

  return (
    <>
      <div ref={versionRef} className={styles.containVersion}>
        <div className={styles.notVersion}>
          <span>Une nouvelle version de votre e-carnet est disponible, cliquez sur ce bouton pour le mettre à jour</span>
          <Button
            type="button"
            className={styles.updateVersion}
            label="Mettre à jour"
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
      <Helmet>
        <title>
          {title}
          {' '}
          -
          {' '}
          {t('global.site_name')}
        </title>
        <meta name="description" content={description} />
      </Helmet>
      <div className={styles.layout}>
        <aside className={cn([styles.aside, menuCollapsed ? styles.asideCollapsed : '', menuVisible ? styles.asideVisible : ''])}>
          <Navigation isCollapsed={menuCollapsed} />
          <button type="button" className={styles.menuToggle} onClick={() => setMenuCollapsed(!menuCollapsed)}>
            <FontAwesomeIcon icon={menuCollapsed ? faChevronRight : faChevronLeft} />
          </button>
          <div className={styles.version}>
            <span
              onClick={() => navigateVersion()}
              style={{ cursor: 'pointer' }}
            >
              {appVersion}
            </span>
            <FontAwesomeIcon
              icon={faArrowsRotate}
              className={styles.refresh}
              onClick={() => setOpenModalRefresh(true)}
              title="Mettre a jour mon E-carnet"
            />
            <Modal
              isOpen={openModalRefresh}
              onRequestClose={() => setOpenModalRefresh(false)}
              style={customStylesRefresh}
              ariaHideApp={false}
            >
              <span className={styles.spanRefresh}>Voulez vous rafraichir votre E-carnet</span>
              <div className={styles.divButton}>
                <Button
                  type="button"
                  label="Annuler"
                  onClick={() => setOpenModalRefresh(false)}
                  className={styles.buttonCancel}
                />
                <Button
                  type="button"
                  label="Rafraichir"
                  onClick={() => window.location.reload()}
                  className={styles.buttonRefresh}
                />
              </div>
            </Modal>
          </div>
        </aside>
        <header className={cn([styles.header, (context.choiceBooklet === 2 ? styles.headerSecurite : ''),
          (context.choiceBooklet === 1 ? styles.headerSanitaire : ''),
          (context.choiceBooklet === 3 ? styles.headerVehicule : '')])}
        >
          <button type="button" className={cn([styles.menuBurger, menuVisible ? styles.menuBurgerVisible : ''])} onClick={() => setMenuVisible(!menuVisible)}>
            <FontAwesomeIcon icon={menuVisible ? faXmark : faBars} />
          </button>
          { !!nameEstablishment && (
          <h2 className={cn([styles.nameEstablishment])}>
            <>
              <Picto className={styles.picto} name="etablissementsBlancSimple" width="40" height="40" />
              { nameEstablishment }
            </>
          </h2>
          )}
          { !isAdminOrSuperAdmin && (Permissions({ permission: 'NOTIFICATION_LIST' }) !== undefined
            || Permissions({ permission: 'BOOKLET_READ' }) !== undefined)
            ? (
              <div className={styles.spacer}>
                <div className={styles.profiles} onClick={() => setOpenModal(!openModal)} aria-hidden="true">
                  <div className={styles.dropdownNotif}>
                    <span className={styles.dropdownLinks}>{t('navigation.notifications')}</span>
                    <Picto className={styles.pictos} name="notificationwhite" width="40" height="40" />
                  </div>
                  <Modal
                    isOpen={openModal}
                    onRequestClose={() => setOpenModal(false)}
                    style={customStyles}
                    ariaHideApp={false}
                  >
                    <div className={styles.button}>
                      <Button
                        type="button"
                        className={styles.close}
                        label={<FontAwesomeIcon icon={faClose} />}
                        onClick={() => setOpenModal(false)}
                      />
                    </div>
                    <Notification />
                  </Modal>
                </div>
              </div>
            ) : null}
          <div role="button" className={styles.profile} onClick={() => setDropdown(!dropdown)} aria-hidden="true">
            <div className={styles.userName}>
              {`${currentUser?.firstName} ${currentUser?.lastName}`}
            </div>
            <img className={styles.userPicture} src={userPicture} alt="" width="32" height="32" />
          </div>
          <div className={cn([styles.dropdown, !dropdown ? 'hidden' : '', 'shadow-lg'])}>
            <Link className={styles.dropdownLink} to={`/Edituser/${currentUser?.id}`}>
              <FontAwesomeIcon icon={faUser} />
              <span className={styles.dropdownLabel}>Mon Profil</span>
            </Link>
            <Link className={styles.dropdownLink} to="/notification">
              <FontAwesomeIcon icon={faBell} />
              <span className={styles.dropdownLabel}>Mes notifications</span>
            </Link>
            <div className={styles.divider} />
            <button type="button" className={styles.dropdownLink} onClick={handleSignOut}>
              <FontAwesomeIcon icon={faSignOut} />
              <span className={styles.dropdownLabel}>Déconnexion</span>
            </button>
          </div>
          {context.userImpersonated ? (
            <Button
              type="button"
              className={styles.switchUser}
              label={<FontAwesomeIcon icon={faUserSecret} />}
              title={t('global.go_back_account')}
              onClick={handleGoBackAccount}
            />
          ) : null}
        </header>
        <main className={cn([styles.main, layout === 'table' ? styles.table : ''])}>
          <QueryError error={queryError} />
          {children}
        </main>
        {isLoading && (
        <div className="loader" />
        )}
      </div>
    </>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  layout: PropTypes.oneOf(['table', '']),
  queryError: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  }),
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

Layout.defaultProps = {
  layout: '',
  queryError: null,
  isLoading: false,
  description: 'Ecarnet - Expertise et accompagnement',
};

export default Layout;
