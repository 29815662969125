/* eslint-disable max-len */
import React from 'react';
import {
  faChevronLeft,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { formatDateToInput } from '../../utils/dates';
// Components
import Layout from '../../components/template/Layout';
import useAppContext from '../../store/useAppContext';
import cn from '../../utils/cn';
import styles from './AddDairy.module.css';
import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Select from '../../components/atoms/Select/Select';
import Textarea from '../../components/atoms/Textarea/Textarea';
import CanvasSignature from '../../components/atoms/Canvas/Canvas';
// Services
import { addDairy } from '../../services/dairy';
import { getBuildingsList } from '../../services/structures';
import { getInterventionsList } from '../../services/interventions';
import { getEquipmentsList } from '../../services/equipments';

function AddDairies() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signature, setSignature] = React.useState('');
  const [context] = useAppContext();
  const [valider, setValider] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [batiment, setBatiment] = React.useState([]);
  const [submit, setSubmit] = React.useState(false);
  const [equipement, setEquipement] = React.useState([]);
  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const validationModalSchema = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const addDairyMutation = useMutation(addDairy, {
    onSuccess: () => {
      setSubmit(true);
      navigate(-1);
      toast.success(t('dairy.succes'));
    },
    onError: () => {
      toast.error(t('dairy.error'));
    },
  });

  const validationSchema = Yup.object({
    data: Yup.string().default(new Date().toLocaleDateString()),
    regie: Yup.boolean().default(false),
    stakeholder: Yup.string().required(t('global.required_field')),
    company: Yup.string().nullable()
      .when('regie', {
        is: false,
        then: Yup.string().required(t('global.required_field')),
      }),
    buildingId: Yup.string().required(t('global.required_field')),
    equipmentId: Yup.string().required(t('global.required_field')),
    interventionType: Yup.string().required(t('global.required_field')),
    signature: Yup.mixed(),
    action: Yup.string(),
  });

  const initialValues = {
    signature: null,
    date: formatDateToInput(new Date()),
    regie: false,
    stakeholder: '',
    company: null,
    buildingId: '',
    equipmentId: '',
    interventionType: '',
    action: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (values.regie) {
        const regieoff = new FormData();
        regieoff.append('signatureFile', values.signature);
        regieoff.append('date', values.date);
        regieoff.append('company', null);
        regieoff.append('stakeholder', values.stakeholder);
        regieoff.append('bookletId', context?.choiceBooklet);
        regieoff.append('buildingId', values.buildingId);
        regieoff.append('equipmentId', values.equipmentId);
        regieoff.append('interventionType', values.interventionType);
        regieoff.append('action', values.action);
        addDairyMutation.mutate(regieoff);
      } else {
        const regieon = new FormData();
        regieon.append('signatureFile', values.signature);
        regieon.append('date', values.date);
        regieon.append('stakeholder', values.stakeholder);
        regieon.append('bookletId', context?.choiceBooklet);
        regieon.append('buildingId', values.buildingId);
        regieon.append('equipmentId', values.equipmentId);
        regieon.append('interventionType', values.interventionType);
        regieon.append('company', values.company);
        regieon.append('action', values.action);
        addDairyMutation.mutate(regieon);
      }
    },
  });

  const getBuildingsListQuery = useQuery(['getBuildingsList'], () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }), {
    onSuccess: (data) => {
      setBatiment(data?.data?.buildings);
      if (data?.data?.buildings?.length <= 1) {
        formik.setFieldValue('buildingId', data?.data?.buildings[0]?.id);
      }
    },
  });

  const getInterventionsListQuery = useQuery(['getInterventionsList'], () => getInterventionsList({
    bookletId: context.choiceBooklet,
    establishmentId: context.choiceEstablishment.id,
  }));

  const getEquipementQuery = useQuery(['getEquipmentsList'], () => getEquipmentsList({
    bookletId: context.choiceBooklet,
    establishmentId: context.choiceEstablishment.id,
    buildingId: formik.values.buildingId,
  }), {
    enabled: !!formik.values.buildingId,
    onSuccess: (data) => {
      setEquipement(data?.data?.equipments);
    },
  });

  const handleValider = () => {
    setValider(false);
  };

  const handletocancel = () => {
    navigate(-1);
    toast.success(t('dairy.cancel'));
  };

  const canvasToPngDataURL = (canvas) => {
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  };

  const sigCanvas = React.useRef({});
  const trim = () => {
    if (sigCanvas.current.isEmpty()) {
      setOpenModal(false);
      toast.error(t('dairy.nochangeSignature'));
      return;
    }
    const trimmedDataURL = canvasToPngDataURL(sigCanvas.current.getTrimmedCanvas());
    setSignature(trimmedDataURL);
    formik.setFieldValue('signature', trimmedDataURL);
    setOpenModal(false);
  };

  const clear = () => {
    sigCanvas.current.clear();
    setSignature('');
  };

  const InterventionSecu = [
    {
      label: 'Commission de sécurité',
      value: 'safety_committee',
    },
    {
      label: 'Contrôle organisme agréé',
      value: 'approved_body_control',
    },
    {
      label: 'Contrôle de maintenance',
      value: 'maintenance_control',
    },
    {
      label: 'Contrôle d\'un laboratoire',
      value: 'laboratory_control',
    },
    {
      label: 'Formation',
      value: 'training',
    },
    {
      label: 'Dépannage/ Travaux',
      value: 'troubleshooting_or_worksite',
    },
    {
      label: 'Autre',
      value: 'other',
    },
  ];

  const InterventionSani = [
    {
      label: 'Contrôle de maintenance',
      value: 'maintenance_control',
    },
    {
      label: 'Contrôle d\'un laboratoire',
      value: 'laboratory_control',
    },
    {
      label: 'Formation',
      value: 'training',
    },
    {
      label: 'Dépannage/ Travaux',
      value: 'troubleshooting_or_worksite',
    },
    {
      label: 'Autre',
      value: 'other',
    },
  ];

  return (
    <>
      <Layout
        title="journal"
        layout="table"
        queryError={
          getBuildingsListQuery?.error
          || getInterventionsListQuery?.error
          || addDairyMutation?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={submit === false ? () => setValider(true) : () => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('dairy.addreturn')}</span>
            </button>
          </div>
          <div className={cn(['row', 'row'])}>
            <h1 className="title">{t('dairy.add')}</h1>
          </div>
        </header>
        <div>
          <form onSubmit={formik.handleSubmit} className="form shadow-sm">
            <div className="form_group">
              <Input
                type="date"
                name="date"
                id="date"
                label={t('dairy.date')}
                value={formatDateToInput(formik.values.date)}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.date && formik.touched.date ? (
                <div className="error">
                  {t(formik.errors.date)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {batiment.length > 1 ? (
                <Select
                  name="buildingId"
                  id="buildingId"
                  label={t('dairy.batiment')}
                  options={
                    batiment?.map(
                      (option) => ({ ...option, label: option.name, value: option.id }),
                    )
                  }
                  value={batiment?.find((option) => option.value === formik.values.buildingId)}
                  onChange={(option) => formik.setFieldValue('buildingId', option.value)}
                  required={formik.values.buildingId === ''}
                  valueInput={formik.values.buildingId}
                  loading={getBuildingsListQuery.isLoading}
                />
              ) : (
                <Input
                  type="text"
                  name="buildingId"
                  id="buildingId"
                  label={t('dairy.batiment')}
                  placeholder={batiment[0]?.name}
                  value={batiment[0]?.name}
                  disabled
                />
              )}
              {formik.errors.buildingId && formik.touched.buildingId ? (
                <div className="error">
                  {t(formik.errors.buildingId)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="company"
                id="company"
                label={t('dairy.society')}
                onChange={(e) => formik.setFieldValue('company', e.target.value.toUpperCase())}
                value={formik.values.company}
                required={formik.values.regie === false}
                disabled={formik.values.regie === true}
              />
              {formik.errors.company && formik.touched.company ? (
                <div className="error">
                  {t(formik.errors.company)}
                </div>
              ) : null }
            </div>
            <div className={cn(['form_group', styles.regie])}>
              <Checkbox
                name="regie"
                id="regie"
                label={t('dairy.regie')}
                value={formik.values.regie}
                onChange={formik.handleChange}
                checked={formik.values.regie}
              />
              {formik.errors.regie && formik.touched.regie ? (
                <div className="error">
                  {t(formik.errors.regie)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="stakeholder"
                id="stakeholder"
                label={t('dairy.intervenant')}
                onChange={(e) => formik.setFieldValue('stakeholder', e.target.value.toUpperCase())}
                value={formik.values.stakeholder}
                required
              />
              {formik.errors.stakeholder && formik.touched.stakeholder ? (
                <div className="error">
                  {t(formik.errors.stakeholder)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {' '}
            </div>
            <div className="form_group">
              <Select
                name="equipmentId"
                id="equipmentId"
                label={t('dairy.equipement')}
                options={
                  equipement?.map(
                    (option) => ({ ...option, label: option?.name, value: option?.id }),
                  )
                }
                value={equipement?.find((option) => option.value === formik.values.equipmentId)}
                onChange={(option) => formik.setFieldValue('equipmentId', option.value)}
                required
                valueInput={formik.values.equipmentId}
                loading={getEquipementQuery?.isLoading}
                disabled={formik.values.buildingId === ''}
              />
              {formik.errors.equipmentId && formik.touched.equipmentId ? (
                <div className="error">
                  {t(formik.errors.equipmentId)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Select
                name="interventionType"
                label={t('dairy.intervention')}
                id="interventionType"
                options={context.choiceBooklet === 1 ? InterventionSani : InterventionSecu}
                value={InterventionSecu?.find((option) => option.value === formik.values.interventionType)}
                onChange={(option) => formik.setFieldValue('interventionType', option.value)}
                required
                valueInput={formik.values.interventionType}
              />
              {formik.errors.interventionType && formik.touched.interventionType ? (
                <div className="error">
                  {t(formik.errors.interventionType)}
                </div>
              ) : null }
            </div>
            <div className="form_group--fullwidth">
              <Textarea
                name="action"
                id="action"
                label={t('dairy.description')}
                onChange={formik.handleChange}
                value={formik.values.action}
                cols={30}
                rows={2}
              />
              {formik.errors.action && formik.touched.action ? (
                <div className="error">
                  {t(formik.errors.action)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <div className="form_infos">
                <small>{t('dairy.signature')}</small>
              </div>
              <button type="button" className={cn(['form_submit', styles.button])} onClick={() => setOpenModal(true)}>
                {signature ? t('dairy.change_signature') : t('dairy.add_signature')}
                <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
              </button>
              {!formik.values.signature && (
                <input
                  autoComplete="off"
                  style={{ opacity: 0, height: 0, width: 0 }}
                  required
                  value={formik.values.signature}
                />
              )}
            </div>
            {signature && (
              <div className="form_group">
                <img
                  src={signature}
                  alt="signature"
                  className={styles.signature}
                />
                {formik.errors.signature && formik.touched.signature ? (
                  <div className="error">
                    {t(formik.errors.signature)}
                  </div>
                ) : null }
              </div>
            )}
            <div className="form_footer">
              <div className="form_infos">
                <small>{t('add_equipment_family.mandatory_fields')}</small>
              </div>
              <Button
                type="submit"
                className="form_submit"
                label={t('dairy.submit')}
              />
            </div>
          </form>
        </div>
        <footer>
          <div className="row mt-20">
            <button
              type="button"
              className="link"
              onClick={submit === false ? () => setValider(true) : () => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('dairy.addreturn')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <button onClick={() => setOpenModal(false)} type="button" className={styles.close}>
          <span aria-hidden="true">&times;</span>
        </button>
        <p className="modal_paragraph">
          { signature ? t('dairy.change_signature') : t('dairy.add_signature')}
        </p>
        {signature && (
          <img
            src={signature}
            alt="signature"
            className={styles.signature}
          />
        )}
        <div className={cn(['modal_actions', styles.signatureContainer])}>
          <CanvasSignature
            canvasProps={{
              className: styles.canvas,
            }}
            name="signature"
            trim={() => trim()}
            clear={() => clear()}
            setSignature={setSignature}
            signature={signature}
            sigCanvas={sigCanvas}
          />
        </div>
      </Modal>
      <Modal
        isOpen={valider}
        onRequestClose={() => setValider(false)}
        style={validationModalSchema}
      >
        <p className="modal_paragraph">{t('dairy.valideerror')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('dairy.annuler')}
            onClick={() => handletocancel(false)}
          />
          <Button
            type="submit"
            className="modal_button"
            label={t('dairy.valider')}
            onClick={() => handleValider(true)}
          />
        </div>
      </Modal>
    </>
  );
}

export default AddDairies;
